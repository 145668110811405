import { getHtmlDivElement, getHtmlElement } from "../html/elements";
import { Language, getLanguage } from "../i18N/translate";

const needsLicense = (): boolean => {
  const licenseExpiration = window.localStorage.getItem("licenseExpiration");
  if (!licenseExpiration) return true;

  const now = new Date();
  const licenseDate = new Date(licenseExpiration);
  if (now > licenseDate) {
    return true;
  }
  return false;
};

export const configureLicense = (): void => {
  const licenseDiv = getHtmlDivElement("license");
  if (needsLicense()) {
    licenseDiv.style.display = "flex";
  }

  getHtmlDivElement("license-btn").addEventListener("click", () => {
    licenseDiv.style.display = "none";

    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 7);
    window.localStorage.setItem("licenseExpiration", endDate.toJSON());
  });

  getHtmlElement("license-btn-more").addEventListener("click", () => {
    let url = "";
    switch (getLanguage()) {
      case Language.Español:
      case Language.English:
      case Language.Valencia:
        url = "https://water4cast.webs.upv.es/acuerdo-de-licencia/";
    }
    window.open(url, "_blank").focus();
  });
};
