/* eslint-disable @typescript-eslint/no-explicit-any */
import { getHtmlElement, getSelectedOptionFor } from "../html/elements";
import { units, variables } from "../i18N/translate";
import {
  Language,
  LocaleKeys,
  getLanguage,
  months,
  translate
} from "../i18N/translate";
import { Grey } from "../map/colors";
import {
  setDateVisibility,
  setForecastVisibility,
  setLeadTimeDescription,
  setLeadTimeVisibility,
  setLegendVisibility,
  setMaxLeadTime,
  setSourceVisibility
} from "./setters";
import { filesContent } from "../tools/cache";
import { readCsv } from "../tools/csv";
import { noDataWarning } from "../tools/warning";
import {
  Forecast,
  HTML_SELECTS,
  Source,
  getCombinedSelectionOption,
  getCsvPath,
  getCumPlotElement,
  getLeadTimeValue,
  getMonthsLeadTimeFor,
  getPlotElement
} from "../utils/queries";
import {
  ModelVariables,
  isDroughtVariable,
  isForecastVariable,
  isParcelVariable
} from "../utils/variables";
import { updateData as updateMapData } from "../map/map";
import { newPlot } from "plotly.js-dist";
import { fillLegendDrought, fillLegendMeteorological } from "../html/content";

const LOCALES = LocaleKeys();
let selectedLatitude: number | undefined;
let selectedLongitude: number | undefined;
export const init = { value: true };

export const setOnChangeEvents = (): void => {
  getHtmlElement(HTML_SELECTS.Variable).onchange = selectVariableChanged;
  getHtmlElement(HTML_SELECTS.Forecast).onchange = selectForecastChanged;
  getHtmlElement(HTML_SELECTS.Month).onchange = selectChanged;
  getHtmlElement(HTML_SELECTS.Week).onchange = selectChanged;
  getHtmlElement(HTML_SELECTS.Day).onchange = selectChanged;
  getHtmlElement(HTML_SELECTS.LeadTime).onchange = selectLeadTimeChanged;
  getHtmlElement(HTML_SELECTS.Source).onchange = selectSourceChanged;
};

const selectVariableChanged = (): void => {
  const variable = getSelectedOptionFor(
    HTML_SELECTS.Variable
  ) as ModelVariables;

  const isForecast = isForecastVariable(variable);
  const isDrought = isDroughtVariable(variable);
  if (isDrought) fillLegendDrought();
  else fillLegendMeteorological();

  selectedLatitude = undefined;
  selectedLongitude = undefined;

  const forecast = isForecast
    ? getSelectedOptionFor(HTML_SELECTS.Forecast)
    : "";

  setForecastVisibility(isForecast);
  setLeadTimeVisibility(isForecast);
  setDateVisibility(forecast);
  setSourceVisibility(forecast);

  selectChanged();
};

export const selectForecastChanged = (): void => {
  const forecast = getSelectedOptionFor(HTML_SELECTS.Forecast) as Forecast;
  const source = getSelectedOptionFor(HTML_SELECTS.Source) as Source;

  let max = getMonthsLeadTimeFor(source);
  switch (forecast) {
    case Forecast.SubSeasonal:
      max = 7;
      break;
    case Forecast.ShortTerm:
      max = 15;
      break;
  }
  setMaxLeadTime(max);
  setDateVisibility(forecast);
  setSourceVisibility(forecast);

  setLeadTimeDescription();
  if (!init.value) selectChanged();
};

const selectLeadTimeChanged = (): void => {
  setLeadTimeDescription();
  selectChanged();
};

export const selectSourceChanged = (): void => {
  const forecast = getSelectedOptionFor(HTML_SELECTS.Forecast);
  if (forecast === Forecast.Seasonal) {
    const source = getSelectedOptionFor(HTML_SELECTS.Source) as Source;
    setMaxLeadTime(getMonthsLeadTimeFor(source));
  }

  if (!init.value) selectChanged();
};

export const selectChanged = async (): Promise<void> => {
  const selectedOption = getCombinedSelectionOption();
  const variable = getSelectedOptionFor(
    HTML_SELECTS.Variable
  ) as ModelVariables;
  const leadTime = isForecastVariable(variable) ? getLeadTimeValue() : 0;

  setLegendVisibility(variable, true);

  if (await updateMapData(selectedOption, leadTime, variable)) {
    setLegendVisibility(variable);
  }

  await updatePlot(selectedLongitude, selectedLatitude);
};

export const updatePlot = async (
  longitude: number,
  latitude: number
): Promise<void> => {
  cleanPlot();

  selectedLatitude = latitude;
  selectedLongitude = longitude;

  const selectedOption = getCombinedSelectionOption();
  const variable = getSelectedOptionFor(
    HTML_SELECTS.Variable
  ) as ModelVariables;
  const forecast = getSelectedOptionFor(HTML_SELECTS.Forecast);

  if (
    latitude === undefined ||
    longitude === undefined ||
    !isForecastVariable(variable)
  ) {
    cleanPlot();
    return;
  }

  const filename = getCsvPath(selectedOption);
  const csvData = filesContent[filename] || (await readCsv(filename));
  filesContent[filename] = csvData;
  if (csvData.errors.length > 0) {
    cleanPlot();
    noDataWarning();
    return;
  }

  const plotDiv = getPlotElement();
  plotDiv.innerHTML = `<div style="width: 500px;" id="chart"></div>`;

  let localeOptions = {};
  switch (getLanguage()) {
    case Language.Español:
      localeOptions = { locale: "es" };
      break;
    case Language.English:
      localeOptions = {};
      break;
    case Language.Valencia:
      localeOptions = { locale: "ca" }; //It is not completly translate
      break;
  }

  if (
    selectedOption.startsWith(Forecast.Seasonal) &&
    !isParcelVariable(variable)
  )
    seasonalPlot(csvData, latitude, longitude, localeOptions);
  else nonSeasonalPlot(forecast, variable, csvData, localeOptions);
};

const cleanPlot = (): void => {
  const plotDiv = getPlotElement();
  plotDiv.innerHTML = "";
  const cumPlotDiv = getCumPlotElement();
  cumPlotDiv.innerHTML = "";
};

const nonSeasonalPlot = (
  forecast: string,
  variable: ModelVariables,
  csvData: any,
  localeOptions: any
): void => {
  const leadTime = getLeadTimeValue();
  const selectedDate: string[] = [];
  const selectedValue: number[] = [];
  const selectedCumValue: number[] = [];
  const dates: string[] = [];
  const values: number[] = [];
  const cumValues: number[] = [];
  let cumValue = 0;

  csvData.data.forEach((contentRow) => {
    if (
      contentRow.Longitude === selectedLongitude &&
      contentRow.Latitude === selectedLatitude
    ) {
      const date =
        forecast === Forecast.Seasonal
          ? `${contentRow.Year_forecast}-${contentRow.Month_forecast}-1`
          : `${contentRow.Year_forecast}-${contentRow.Month_forecast}-${contentRow.Day_forecast}`;

      values.push(contentRow.ensemble0);
      dates.push(date);
      cumValue += contentRow.ensemble0;
      cumValues.push(cumValue);
      if (contentRow.Leadtime === leadTime) {
        selectedValue.push(contentRow.ensemble0);
        selectedCumValue.push(cumValue);
        selectedDate.push(date);
      }
    }
  });

  if (values.length === 0) {
    cleanPlot();
    return;
  }

  let title = "";
  switch (forecast) {
    case Forecast.Seasonal:
      title = translate(LOCALES.plotTitleSeasonal);
      break;
    case Forecast.SubSeasonal:
      title = translate(LOCALES.plotTitleSubSeasonal);
      break;
    case Forecast.ShortTerm:
      title = translate(LOCALES.plotTitleShortTerm);
      break;
  }

  const nonArgs = "";
  newPlot(
    "chart",
    [
      {
        x: dates,
        y: values,
        mode: "lines",
        type: "scatter"
      },
      {
        x: selectedDate,
        y: selectedValue,
        mode: "markers",
        type: "scatter",
        hoverinfo: "skip"
      }
    ],
    {
      title,
      showlegend: false,
      hovermode: "x unified",
      yaxis: {
        title: {
          text: `${translate(
            LOCALES[variables[getSelectedOptionFor(HTML_SELECTS.Variable)]],
            nonArgs
          )} ${
            units[getSelectedOptionFor(HTML_SELECTS.Variable)]
              ? `[${units[getSelectedOptionFor(HTML_SELECTS.Variable)]}]`
              : ""
          }`
        },
        hoverformat: ".2f"
      },
      yaxis2: {
        title: "fdsdf"
      },
      xaxis: {
        title: {
          text: "<b>Water4Cast©</b> https://app.water4cast.webs.upv.es/",
          font: {
            family: "Courier New, monospace",
            size: 10,
            color: Grey.Base
          }
        }
      }
    },
    localeOptions
  );

  cummulativePlot(
    variable,
    forecast,
    localeOptions,
    dates,
    cumValues,
    selectedDate,
    selectedCumValue
  );
};

const seasonalPlot = (
  csvData: any,
  latitude: number,
  longitude: number,
  localeOptions: any
): void => {
  const valuesByMonth: { month: string; values: number[] }[] = [];
  csvData.data.forEach((contentRow) => {
    if (
      contentRow.Longitude === selectedLongitude &&
      contentRow.Latitude === selectedLatitude
    ) {
      const key = contentRow.Month_forecast;
      const values: number[] = [];
      Object.keys(contentRow).forEach((col) => {
        if (col.includes("ensemble")) values.push(contentRow[col]);
      });
      valuesByMonth.push({ month: key, values });
    }
  });

  if (valuesByMonth.length === 0) {
    cleanPlot();
    return;
  }

  const selectedMonth = months[valuesByMonth[getLeadTimeValue()].month];
  const title = translate(
    LOCALES.plotTitleSeasonalCoordinate,
    getSelectedOptionFor(HTML_SELECTS.Source),
    latitude.toString(),
    longitude.toString(),
    translate(selectedMonth)
  );

  const nonArgs = "";
  newPlot(
    "chart",
    valuesByMonth.map((mv, index) => {
      const monthSelected = getLeadTimeValue();
      return {
        y: mv.values,
        type: "box",
        name: translate(months[mv.month]),
        fillcolor: monthSelected === index ? "" : Grey.White
      };
    }),
    {
      title,
      showlegend: false,
      hovermode: "x unified",
      yaxis: {
        title: {
          text: `${translate(
            LOCALES[variables[getSelectedOptionFor(HTML_SELECTS.Variable)]],
            nonArgs
          )} ${
            units[getSelectedOptionFor(HTML_SELECTS.Variable)]
              ? `[${units[getSelectedOptionFor(HTML_SELECTS.Variable)]}]`
              : ""
          }`
        },
        hoverformat: ".2f"
      },
      xaxis: {
        title: {
          text: "<b>Water4Cast©</b> https://app.water4cast.webs.upv.es/",
          font: {
            family: "Courier New, monospace",
            size: 10,
            color: Grey.Base
          }
        },
        hoverformat: ".2f"
      }
    },
    localeOptions
  );
};

const cummulativePlot = (
  variable: ModelVariables,
  forecast: string,
  localeOptions: any,
  dates: string[],
  values: number[],
  selectedDate: string[],
  selectedValue: number[]
): void => {
  if (isParcelVariable(variable)) {
    const plotDiv = getCumPlotElement();
    plotDiv.innerHTML = `<div style="width: 500px;" id="chart-cum"></div>`;

    let title = "";
    switch (forecast) {
      case Forecast.Seasonal:
        title = translate(LOCALES.cumPlotTitleSeasonal);
        break;
      case Forecast.SubSeasonal:
        title = translate(LOCALES.cumPlotTitleSubSeasonal);
        break;
      case Forecast.ShortTerm:
        title = translate(LOCALES.cumPlotTitleShortTerm);
        break;
    }

    const nonArgs = "";

    newPlot(
      "chart-cum",
      [
        {
          x: dates,
          y: values,
          mode: "lines",
          type: "scatter"
        },
        {
          x: selectedDate,
          y: selectedValue,
          mode: "markers",
          type: "scatter",
          hoverinfo: "skip"
        }
      ],
      {
        title,
        showlegend: false,
        hovermode: "x unified",
        yaxis: {
          title: {
            text: `${translate(
              LOCALES[variables[getSelectedOptionFor(HTML_SELECTS.Variable)]],
              nonArgs
            )} ${
              units[getSelectedOptionFor(HTML_SELECTS.Variable)]
                ? `[${units[getSelectedOptionFor(HTML_SELECTS.Variable)]}]`
                : ""
            }`
          },
          hoverformat: ".2f"
        },
        yaxis2: {
          title: "fdsdf"
        },
        xaxis: {
          title: {
            text: "<b>Water4Cast©</b> https://app.water4cast.webs.upv.es/",
            font: {
              family: "Courier New, monospace",
              size: 10,
              color: Grey.Base
            }
          }
        }
      },
      localeOptions
    );
  }
};
