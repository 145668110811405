/* eslint-disable @typescript-eslint/no-explicit-any */
import { Feature, GeoJsonProperties, Geometry } from "geojson";
import {
  Control,
  FeatureGroup,
  GeoJSON,
  LatLngExpression,
  Layer,
  LeafletMouseEvent,
  Map,
  PathOptions,
  StyleFunction,
  TileLayer,
  control,
  geoJSON,
  map,
  tileLayer
} from "leaflet";
import { LocaleKeys, translate } from "../i18N/translate";
import { filesContent } from "../tools/cache";
import { readCsv } from "../tools/csv";
import { noDataWarning } from "../tools/warning";
import {
  transparentStyle,
  blueYellowOrangeGradientStyle,
  getStyle,
  greenGreenGradientStyle,
  redOrangeGreenGradientStyle,
  reservoirStyle,
  stationStyle,
  whiteBlueGradientStyle,
  fishStyle,
  celesteIntenseStyle
} from "./styles";
import { getGeoJSON } from "./geojsons";
import {
  isDroughtVariable,
  isParcelVariable,
  isSPI,
  isStatsVariable,
  ModelVariables
} from "../utils/variables";
import GeoRasterLayer from "georaster-layer-for-leaflet";
import parse_georaster from "georaster";
import {
  HTML_SELECTS,
  getCsvPath,
  getDateFromWeekFile,
  getSelectedDateFor
} from "../utils/queries";
import {
  showFishPopup,
  showNonForecastPopup,
  showPopup,
  showPopupDrought,
  showShortTermPopup,
  showStationPopup,
  showStatsPopup
} from "./popup";
import {
  setDateVisibility,
  setForecast,
  setForecastVisibility,
  setLeadTimeDescription,
  setLeadTimeVisibility,
  setLegendVisibilityForHumidity,
  setSourceVisibility
} from "../index/setters";
import { hideLoader, showLoader } from "../html/loader";

let myMap: Map;
let coordinates: LatLngExpression | undefined;
let selectedLongitude = 0;
let selectedLatitude = 0;
let previousVariable = "";
let layersControl: Control.Layers;
let updatePlot: (longitude: number, latitude: number) => Promise<void>;
const LOCALES = LocaleKeys();

const GEOJSON_PATHS = {
  grid: "geojsons/grid.geojson",
  subBasins: "geojsons/subbasins.geojson",
  flowStations: "geojsons/flow_stations.geojson",
  reservoirStations: "geojsons/reservoir_stations.geojson",
  evaReservoirs: "geojsons/eva_reservoirs.geojson",
  udas: "geojsons/udas.geojson",
  fish: "geojsons/fish.geojson",
  parcels: "geojsons/parcels.geojson"
};

type W4CFeature = Feature<
  Geometry,
  {
    [name: string]: GeoJsonProperties;
  }
>;

type IndexedFeatures = { [id: string]: W4CFeature };

const onMeshFeature = (
  feature: Feature<Geometry, GeoJsonProperties>,
  layer: Layer
) => {
  layerOn(feature, layer);
  showPopup(feature, layer);
};

const onDroughtFeature = (
  feature: Feature<Geometry, GeoJsonProperties>,
  layer: Layer
) => {
  layerOn(feature, layer);
  showPopupDrought(feature, layer);
};

const onStationFeature = (
  feature: Feature<Geometry, GeoJsonProperties>,
  layer: Layer
) => {
  layerOn(feature, layer);

  showStationPopup(feature, layer);
};

const onShortTermFeature = (
  feature: Feature<Geometry, GeoJsonProperties>,
  layer: Layer
) => {
  layerOn(feature, layer);

  showShortTermPopup(feature, layer);
};

const onStatsFeature = (
  feature: Feature<Geometry, GeoJsonProperties>,
  layer: Layer
) => {
  layerOn(feature, layer);
  showStatsPopup(feature, layer);
};

const onNonForecastFeature = (
  feature: Feature<Geometry, GeoJsonProperties>,
  layer: Layer
) => {
  layerOn(feature, layer);

  showNonForecastPopup(feature, layer);
};

const onFishFeature = (
  feature: Feature<Geometry, GeoJsonProperties>,
  layer: Layer
) => {
  showFishPopup(feature, layer);
};

const openStreetMap: TileLayer = tileLayer(
  "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  {
    attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a>',
    maxZoom: 18
  }
);

const terrainMap: TileLayer = tileLayer.wms(
  "https://www.ign.es/wms-inspire/pnoa-ma?SERVICE=WMS&",
  {
    layers: "OI.OrthoimageCoverage",
    format: "image/jpeg",
    transparent: true,
    version: "1.3.0",
    attribution: translate(LOCALES.attributionIGN)
  }
);

const baseMaps: { [key: string]: TileLayer } = {
  OpenStreetMap: openStreetMap,
  [translate(LOCALES.terrain)]: terrainMap
};

const overlayMaps: { [key: string]: TileLayer | GeoJSON } = {};

const layers = new FeatureGroup();

const joinFeaturesWithData = (
  option: string,
  featureCollection: GeoJSON.FeatureCollection,
  csvData: any,
  leadTime: number,
  index?: boolean
): GeoJSON.FeatureCollection => {
  switch (option) {
    case "terciles":
      return joinFeaturesWithTercilesData(featureCollection, csvData, leadTime);
    case "stats":
      return joinFeaturesWithStatsData(featureCollection, csvData, leadTime);
    default:
      return joinFeaturesWithEnsembleData(
        featureCollection,
        csvData,
        leadTime,
        index
      );
  }
};

const joinFeaturesWithTercilesData = (
  featureCollection: GeoJSON.FeatureCollection,
  csvData: any,
  leadTime: number
): GeoJSON.FeatureCollection => {
  const joinedData: W4CFeature[] = [];

  const features: IndexedFeatures = {};
  featureCollection.features.forEach(
    (f) => (features[f.properties.latitude + "-" + f.properties.longitude] = f)
  );

  csvData.data.forEach((contentRow) => {
    if (contentRow.Leadtime !== leadTime) return;

    const feature = features[contentRow.Latitude + "-" + contentRow.Longitude];
    if (!feature) return;

    const data: W4CFeature = {
      ...feature,
      properties: {
        ...feature.properties,
        Lower: contentRow.Lower_tercile_prob,
        Medium: contentRow.Medium_tercile_prob,
        Upper: contentRow.Upper_tercile_prob,
        SuccessRate: contentRow.Porcentaje_de_acierto
      }
    };

    joinedData.push(data);
  });

  const joinedFeatureCollection: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: joinedData
  };

  return joinedFeatureCollection;
};

const joinFeaturesWithEnsembleData = (
  featureCollection: GeoJSON.FeatureCollection,
  csvData: any,
  leadtime: number,
  index: boolean | undefined
): GeoJSON.FeatureCollection => {
  const joinedData: W4CFeature[] = [];

  let maxValue = Number.MIN_SAFE_INTEGER;
  let minValue = Number.MAX_SAFE_INTEGER;
  if (index) {
    maxValue = 1;
    minValue = 0;
  } else {
    csvData.data.forEach((contentRow) => {
      if (contentRow.ensemble0 === -999) return;
      if (contentRow.ensemble0 > maxValue) {
        maxValue = contentRow.ensemble0;
      }
      if (contentRow.ensemble0 < minValue) {
        minValue = contentRow.ensemble0;
      }
    });
  }

  const features: IndexedFeatures = {};
  featureCollection.features.forEach(
    (f) => (features[f.properties.latitude + "-" + f.properties.longitude] = f)
  );

  csvData.data.forEach((contentRow) => {
    if (contentRow.Leadtime !== leadtime) return;

    const feature = features[contentRow.Latitude + "-" + contentRow.Longitude];
    if (!feature) return;

    const data: W4CFeature = {
      ...feature,
      properties: {
        ...feature.properties,
        value: contentRow.ensemble0,
        rate: (maxValue === Number.MIN_SAFE_INTEGER
          ? 0
          : (contentRow.ensemble0 - minValue) / (maxValue - minValue)) as any
      }
    };

    joinedData.push(data);
  });

  const joinedFeatureCollection: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: joinedData
  };

  return joinedFeatureCollection;
};

const joinFeaturesWithStatsData = (
  featureCollection: GeoJSON.FeatureCollection,
  csvData: any,
  leadTime: number
): GeoJSON.FeatureCollection => {
  const joinedData: W4CFeature[] = [];
  let maxValue = Number.MIN_SAFE_INTEGER;
  let minValue = Number.MAX_SAFE_INTEGER;
  csvData.data.forEach((contentRow) => {
    if (contentRow.mean === -999) return;
    if (contentRow.mean > maxValue) {
      maxValue = contentRow.mean;
    }
    if (contentRow.mean < minValue) {
      minValue = contentRow.mean;
    }
  });

  const features: IndexedFeatures = {};
  featureCollection.features.forEach(
    (f) => (features[f.properties.latitude + "-" + f.properties.longitude] = f)
  );

  csvData.data.forEach((contentRow) => {
    if (contentRow.Leadtime !== leadTime) return;

    const feature = features[contentRow.Latitude + "-" + contentRow.Longitude];
    if (!feature) return;

    const data: W4CFeature = {
      ...feature,
      properties: {
        ...feature.properties,
        mean: contentRow.mean,
        rate: (maxValue === Number.MIN_SAFE_INTEGER
          ? 0
          : (contentRow.mean - minValue) / (maxValue - minValue)) as any
      }
    };

    joinedData.push(data);
  });

  const joinedFeatureCollection: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: joinedData
  };

  return joinedFeatureCollection;
};

const onMapClick = () => {
  coordinates = undefined;
  updatePlot(undefined, undefined);
};

export const initializeMap = async (
  updateThePlot: (longitude: number, latitude: number) => Promise<void>
): Promise<void> => {
  updatePlot = updateThePlot;
  myMap = map("map", {
    center: getCenterOfCv(),
    zoom: 7,
    layers: Object.values(baseMaps),
    scrollWheelZoom: true
  });
  myMap.on("click", onMapClick);

  const basinsGeojson =
    filesContent[GEOJSON_PATHS.subBasins] ||
    (await getGeoJSON(GEOJSON_PATHS.subBasins));
  const basinLayer = geoJSON(basinsGeojson);

  control.scale().addTo(myMap);

  overlayMaps[translate(LOCALES.basins)] = basinLayer;

  layersControl = control
    .layers(baseMaps, overlayMaps, { collapsed: true })
    .addTo(myMap);
};

export const getCenterOfCv = (): LatLngExpression => {
  return [39.39, -1];
};

const addRaster = async (fileName: string) => {
  const rasterFilename = `tiffs/${fileName}.tif`;

  await fetch(rasterFilename)
    .then((response) => response.arrayBuffer())
    .then((arrayBuffer) => {
      parse_georaster(arrayBuffer).then((georaster) => {
        const layer = new GeoRasterLayer({
          georaster: georaster,
          opacity: 0.7,
          resolution: 64
        });
        overlayMaps[translate(LOCALES.forecast)] = layer;
        if (layersControl)
          layersControl.addOverlay(layer, translate(LOCALES.forecast));
        layers.addLayer(layer);
        layer.addTo(myMap);
      });
    });
  hideLoader();
};

export const updateData = async (
  selectedOption: string,
  leadTime: number,
  variable: ModelVariables
): Promise<boolean> => {
  if (variable === ModelVariables.Humidity) {
    setHumidityView();
  }
  if (variable === ModelVariables.PotentialHabitat) {
    setFishView();
  }

  if (isSPI(variable)) {
    setSpiView();
  }
  const prefix = getPrefix(selectedOption, variable);
  const fileName = prefix + selectedOption;
  const filename = getCsvPath(fileName);

  removeLayers();

  const newLayer = await getLayerFor(variable, filename, leadTime);
  if (newLayer === null) return false;
  if (variable === ModelVariables.Humidity) return;

  overlayMaps[translate(LOCALES.forecast)] = newLayer;
  if (layersControl)
    layersControl.addOverlay(newLayer, translate(LOCALES.forecast));
  myMap.addLayer(newLayer);
  layers.addLayer(newLayer);

  reopenPopup(newLayer, variable);

  previousVariable = variable;
  return true;
};

const setHumidityView = () => {
  removeLayers();
  setDateVisibility("subSeasonal");
  setForecastVisibility(false);
  setLeadTimeVisibility(false);
  setSourceVisibility("subSeasonal");

  const fileName = `humedad-suelo_S2S_NCEP_CFSv2_${getDateFromWeekFile(
    getSelectedDateFor(HTML_SELECTS.Week)
  )}`;
  showLoader();
  addRaster(fileName);
  setLegendVisibilityForHumidity();
};

const setFishView = () => {
  removeLayers();
  setDateVisibility("subSeasonal");
  setForecastVisibility(false);
  setLeadTimeVisibility(false);
  setSourceVisibility("subSeasonal");
};

const setSpiView = () => {
  setDateVisibility("seasonal");
  setSourceVisibility("seasonal");
  setForecast("seasonal");
  setForecastVisibility(false);
  setLeadTimeDescription();
};

export const getPrefix = (
  selectedOption: string,
  variable: ModelVariables
): string => {
  if (
    selectedOption.startsWith("subseasonal_") ||
    selectedOption.startsWith("shortterm_")
  )
    return "";
  if (isStatsVariable(variable)) return "stats_";
  if (isParcelVariable(variable)) return "";
  if (selectedOption.startsWith("seasonal_")) return "terciles_";
  return "";
};

const getLayerFor = async (
  variable: ModelVariables,
  filename: string,
  leadTime: number
): Promise<GeoJSON | null> => {
  const csvData = filesContent[filename] || (await readCsv(filename));

  filesContent[filename] = csvData;
  const hasTerciles = filename.includes("terciles");
  const option = filename.includes("terciles")
    ? "terciles"
    : filename.includes("stats")
    ? "stats"
    : "";
  switch (variable) {
    case ModelVariables.Flow:
      const flowGeojson =
        filesContent[GEOJSON_PATHS.flowStations] ||
        (await getGeoJSON(GEOJSON_PATHS.flowStations));
      return geoJSON(flowGeojson, {
        pointToLayer: stationStyle,
        onEachFeature: onStationFeature
      });
    case ModelVariables.Inflow:
      const resStationsGeojson =
        filesContent[GEOJSON_PATHS.reservoirStations] ||
        (await getGeoJSON(GEOJSON_PATHS.reservoirStations));
      return geoJSON(resStationsGeojson, {
        pointToLayer: reservoirStyle,
        onEachFeature: onStationFeature
      });
    case ModelVariables.Evaporation:
      if (csvData.errors.length > 0) {
        noDataWarning();
        return null;
      }
      const evaReservoirsGeojson =
        filesContent[GEOJSON_PATHS.evaReservoirs] ||
        (await getGeoJSON(GEOJSON_PATHS.evaReservoirs));
      const rerFeatureCollection = joinFeaturesWithData(
        option,
        evaReservoirsGeojson,
        csvData,
        leadTime
      );
      if (rerFeatureCollection.features.length === 0) {
        noDataWarning();
        return null;
      }

      return geoJSON(rerFeatureCollection, {
        style: getStyleFor(hasTerciles, variable),
        onEachFeature: option === "stats" ? onStatsFeature : onStationFeature
      });
    case ModelVariables.GrossIrrigationNeeds:
    case ModelVariables.NetIrrigationNeeds:
    case ModelVariables.CropWaterStress:
      if (csvData.errors.length > 0) {
        noDataWarning();
        return null;
      }

      const udasGeojson =
        filesContent[GEOJSON_PATHS.udas] ||
        (await getGeoJSON(GEOJSON_PATHS.udas));
      const udasFeatureCollection = joinFeaturesWithData(
        option,
        udasGeojson,
        csvData,
        leadTime,
        true
      );
      if (udasFeatureCollection.features.length === 0) {
        noDataWarning();
        return null;
      }

      return geoJSON(udasFeatureCollection, {
        style: getStyleFor(hasTerciles, variable),
        onEachFeature:
          variable === ModelVariables.CropWaterStress
            ? onNonForecastFeature
            : option === "stats"
            ? onStatsFeature
            : onShortTermFeature
      });
    case ModelVariables.PotentialHabitat:
      const fishGeojson =
        filesContent[GEOJSON_PATHS.fish] ||
        (await getGeoJSON(GEOJSON_PATHS.fish));
      return geoJSON(fishGeojson, {
        pointToLayer: fishStyle,
        onEachFeature: onFishFeature
      });
    case ModelVariables.NetIrrigationNeedsPlotCubicMeters:
    case ModelVariables.NetIrrigationNeedsMillimeters:
    case ModelVariables.CropWaterStressPlot:
      if (csvData.errors.length > 0) {
        noDataWarning();
        return null;
      }

      const parcelsGeojson =
        filesContent[GEOJSON_PATHS.parcels] ||
        (await getGeoJSON(GEOJSON_PATHS.parcels));
      const index = true;
      const parcelsFeatureCollection = joinFeaturesWithData(
        option,
        parcelsGeojson,
        csvData,
        leadTime,
        index
      );

      if (parcelsFeatureCollection.features.length === 0) {
        noDataWarning();
        return null;
      }

      return geoJSON(parcelsFeatureCollection, {
        style: getStyleFor(hasTerciles, variable),
        onEachFeature:
          variable === ModelVariables.CropWaterStressPlot
            ? onNonForecastFeature
            : onShortTermFeature
      });
    case ModelVariables.Humidity:
      break;
    default:
      if (csvData.errors.length > 0) {
        noDataWarning();
        return null;
      }
      const grid = await getGridFor();
      const featureCollection = joinFeaturesWithData(
        option,
        grid,
        csvData,
        leadTime
      );
      if (featureCollection.features.length === 0) {
        noDataWarning();
        return null;
      }
      let onEachFeature = hasTerciles ? onMeshFeature : onShortTermFeature;
      if (isDroughtVariable(variable)) onEachFeature = onDroughtFeature;
      return geoJSON(featureCollection, {
        style: getStyleFor(hasTerciles, variable),
        onEachFeature
      });
  }
};

const getGridFor = async (): Promise<GeoJSON.FeatureCollection> => {
  return (
    filesContent[GEOJSON_PATHS.grid] || (await getGeoJSON(GEOJSON_PATHS.grid))
  );
};

const getStyleFor = (
  terciles: boolean,
  variable: ModelVariables
): PathOptions | StyleFunction<{ [name: string]: any }> => {
  if (terciles) return getStyle;

  switch (variable) {
    case ModelVariables.Precipitation:
    case ModelVariables.Wind:
      return whiteBlueGradientStyle;
    case ModelVariables.Temperature:
    case ModelVariables.MinTemperature:
    case ModelVariables.MaxTemperature:
    case ModelVariables.SolarRadiation:
      return blueYellowOrangeGradientStyle;

    case ModelVariables.Humidity:
      return transparentStyle;
    case ModelVariables.Evaporation:
      return celesteIntenseStyle;
    case ModelVariables.GrossIrrigationNeeds:
    case ModelVariables.NetIrrigationNeeds:
    case ModelVariables.NetIrrigationNeedsPlotCubicMeters:
    case ModelVariables.NetIrrigationNeedsMillimeters:
      return greenGreenGradientStyle;
    case ModelVariables.CropWaterStress:
    case ModelVariables.CropWaterStressPlot:
      return redOrangeGreenGradientStyle;
    default:
      return whiteBlueGradientStyle;
  }
};

const reopenPopup = (newLayer: GeoJSON<any>, variable: string): void => {
  if (coordinates && previousVariable === variable) {
    newLayer.eachLayer((layer) => {
      const pop = layer.getPopup();
      if (!pop) return;
      const options = pop.options;
      if (
        options.className === `lat:${selectedLatitude},lng:${selectedLongitude}`
      )
        layer.openPopup(coordinates);
    });
  }
};
const layerOn = (
  feature: Feature<Geometry, GeoJsonProperties>,
  layer: Layer
): void => {
  if (feature.properties.value && feature.properties.value === -999) return;
  layer.on({
    click: (e: LeafletMouseEvent) => {
      coordinates = e.latlng;
      selectedLongitude = feature.properties.longitude;
      selectedLatitude = feature.properties.latitude;
      updatePlot(selectedLongitude, selectedLatitude);
    }
  });
};
function removeLayers() {
  const layerToRemove = overlayMaps[translate(LOCALES.forecast)];
  if (layerToRemove) {
    layers.removeLayer(layers.getLayers()[0]);
    myMap.removeLayer(layerToRemove);
    layersControl.removeLayer(layerToRemove);
    delete overlayMaps[translate(LOCALES.forecast)];
  }
}
