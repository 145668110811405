import { fillTextContent } from "./html/content";
import { initializeMap } from "./map/map";
import { addListeners, recoverLogin } from "./index/listeners";
import {
  init,
  selectChanged,
  selectForecastChanged,
  selectSourceChanged,
  setOnChangeEvents,
  updatePlot
} from "./index/onChange";
import { setSelectVisibility } from "./index/setters";
import { configureLicense } from "./index/license";
import { Login } from "./tools/login";

const initializeApp = async (): Promise<void> => {
  await Login.configureClient();

  fillTextContent();
  setSelectVisibility();
  addListeners();
  setOnChangeEvents();
  configureLicense();
  initializeMap(updatePlot);
  selectSourceChanged();
  selectForecastChanged();
  selectChanged();
  recoverLogin();

  init.value = false;
};

initializeApp();
