import moment from "moment";
import {
  getHtmlElement,
  getHtmlInputValue,
  getSelectedOptionFor
} from "../html/elements";
import { filesContent } from "../tools/cache";
import { readCsv } from "../tools/csv";
import { max, min } from "./math";
import {
  getSubSeasonalSource,
  isForecastVariable,
  ModelVariables,
  getShortTermSource
} from "./variables";
import { getPrefix } from "../map/map";

export enum HTML_SELECTS {
  Variable = "variable-select",
  Forecast = "forecast-select",
  Month = "month-select",
  Week = "week-select",
  Day = "day-select",
  LeadTime = "lead-time-forecast-select",
  Source = "source-select"
}

const STATS_KEY = "stats_";
const CSVS_PATH = "csvs/";
const CSV_EXTENSION = ".csv";

export enum Forecast {
  Seasonal = "seasonal",
  SubSeasonal = "subSeasonal",
  ShortTerm = "shortTerm"
}

export enum Source {
  CMCC = "CMCC_SPSv35",
  DWD = "DWD_GCFS21",
  ECMWF = "ECMWF_SEAS5",
  MF = "MF_System8"
}

export const getLeadTimeValue = (): number => {
  return Number(getHtmlInputValue(HTML_SELECTS.LeadTime));
};

export const getPlotElement = (): HTMLElement => {
  return getHtmlElement("plot");
};

export const getCumPlotElement = (): HTMLElement => {
  return getHtmlElement("plot-cum");
};

export const getSelectedDateFor = (id: string): string => {
  return getHtmlInputValue(id);
};

export const getMonthsLeadTimeFor = (source: Source): number => {
  const sourceToMonthsMap: { [key: string]: number } = {
    [Source.CMCC]: 5,
    [Source.DWD]: 5,
    [Source.ECMWF]: 6,
    [Source.MF]: 6
  };

  return sourceToMonthsMap[source];
};

export const getMaxValueFrom = async (filename: string): Promise<number> => {
  const csvData = filesContent[filename] || (await readCsv(filename));
  filesContent[filename] = csvData;
  if (csvData.errors.length > 0) {
    return -999;
  }
  const hasStats = filename.includes(STATS_KEY);

  const values: number[] = csvData.data.map((contentRow) =>
    hasStats ? contentRow.mean : contentRow.ensemble0
  );

  return max(values);
};

export const getMinValueFrom = async (filename: string): Promise<number> => {
  const csvData = filesContent[filename] || (await readCsv(filename));
  filesContent[filename] = csvData;
  if (csvData.errors.length > 0) {
    return -999;
  }
  const hasStats = filename.includes(STATS_KEY);

  const values: number[] = csvData.data
    .map((contentRow) => (hasStats ? contentRow.mean : contentRow.ensemble0))
    .filter((x) => x !== -999);

  return min(values);
};

export const getDateFromWeek = (yearWeek: string): string => {
  const values = yearWeek.split("-");
  const weekNumber = Number(values[1].replace("W", ""));
  const year = Number(values[0]);

  return moment()
    .year(year)
    .isoWeek(weekNumber)
    .startOf("isoWeek")
    .format("YYYY-MM-DD");
};

export const getDateFromWeekFile = (yearWeek: string): string => {
  const values = yearWeek.split("-");
  const weekNumber = Number(values[1].replace("W", ""));
  const year = Number(values[0]);

  return moment()
    .year(year)
    .isoWeek(weekNumber)
    .startOf("isoWeek")
    .format("YYYY_MM_DD");
};

export const getFilenameForLegend = (): string => {
  const selectedOption = getCombinedSelectionOption();
  const variable = getSelectedOptionFor(
    HTML_SELECTS.Variable
  ) as ModelVariables;
  const prefix = getPrefix(selectedOption, variable);
  const fileName = prefix + selectedOption;
  return getCsvPath(fileName);
};

export const getCombinedSelectionOption = (): string => {
  const variable = getSelectedOptionFor(
    HTML_SELECTS.Variable
  ) as ModelVariables;

  const forecast = isForecastVariable(variable)
    ? getSelectedOptionFor(HTML_SELECTS.Forecast)
    : "";
  let date = "";
  switch (forecast) {
    case Forecast.Seasonal:
      date = getSelectedDateFor(HTML_SELECTS.Month);
      break;
    case Forecast.SubSeasonal:
      date = getDateFromWeek(getSelectedDateFor(HTML_SELECTS.Week));
      break;
    case Forecast.ShortTerm:
    case "":
      date = getSelectedDateFor(HTML_SELECTS.Day);
      break;
  }

  date = date.replace(/-/g, "_");
  const source = getSource(forecast as Forecast, variable);
  const selectedOption =
    forecast === ""
      ? `${date}_${variable}_${source}`
      : `${forecast.toLowerCase()}_${date}_${variable}_${source}`;

  return selectedOption;
};

const getSource = (forecast: Forecast, variable: ModelVariables): string => {
  if (forecast === Forecast.Seasonal)
    return getSelectedOptionFor(HTML_SELECTS.Source);
  if (forecast === Forecast.SubSeasonal) return getSubSeasonalSource(variable);
  return getShortTermSource(variable);
};

export const getCsvPath = (fileName: string): string => {
  return `${CSVS_PATH}${fileName}${CSV_EXTENSION}`;
};
