import { setContent } from "../html/elements";
import { LocaleKeys, translate } from "../i18N/translate";
const LOCALES = LocaleKeys();

export const noDataWarning = (): void => {
  setContent("modal-alert", translate(LOCALES.noDataAvailable));
  setTimeout(() => {
    setContent("modal-alert", "");
  }, 4000);
};
