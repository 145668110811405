import { LocaleKeys, translate } from "../i18N/translate";
import {
  setLabel,
  setContent,
  setInnerHtml,
  setTitle,
  setOption
} from "./elements";
const LOCALES = LocaleKeys();

export const fillTextContent = (): void => {
  setContent("date-title", translate(LOCALES.forecastDate));

  setContent("forecast-title", translate(LOCALES.forecast));

  setOption("forecast-seasonal", translate(LOCALES.seasonal));
  setOption("forecast-sub-seasonal", translate(LOCALES.subSeasonal));
  setOption("forecast-short-term", translate(LOCALES.shortTerm));

  setContent("forecast-lead-time-title", translate(LOCALES.anticipationPeriod));

  setContent("variable-title", translate(LOCALES.variable));

  setLabel(
    "meteorological-variable",
    translate(LOCALES.forecastMeteorological)
  );
  setOption("variable-prec", translate(LOCALES.precipitation));
  setOption("variable-temp", translate(LOCALES.temperature));
  setOption("variable-tasmin", translate(LOCALES.temperatureMin));
  setOption("variable-tasmax", translate(LOCALES.temperatureMax));

  setLabel("eco-variable", translate(LOCALES.forecastEco));
  setOption("variable-flow", translate(LOCALES.flow));
  setOption("variable-inflow", translate(LOCALES.inflow));
  setOption("variable-evap", translate(LOCALES.evaporation));
  setOption("variable-humid", translate(LOCALES.humidity));

  setLabel("agricultural-variable", translate(LOCALES.forecastAgricultural));
  setOption(
    "variable-birrigneeds",
    translate(LOCALES.grossIrrigationNeeds, "m³")
  );
  setOption(
    "variable-nirrigneeds",
    translate(LOCALES.netIrrigationNeeds, "mm")
  );
  setOption("variable-cws", translate(LOCALES.cropWaterStress));

  setLabel("fire-risk-indicators", translate(LOCALES.fireIndexes));
  setLabel(
    "drought-indexes-variable-spi",
    translate(LOCALES.droughtIndexesSpi)
  );
  setLabel(
    "drought-indexes-variable-spei",
    translate(LOCALES.droughtIndexesSpei)
  );
  setOption("variable-spi6", translate(LOCALES.spi6));
  setOption("variable-spi12", translate(LOCALES.spi12));
  setOption("variable-spi18", translate(LOCALES.spi18));
  setOption("variable-spi24", translate(LOCALES.spi24));
  setOption("variable-spei6", translate(LOCALES.spei6));
  setOption("variable-spei12", translate(LOCALES.spei12));
  setOption("variable-spei18", translate(LOCALES.spei18));
  setOption("variable-spei24", translate(LOCALES.spei24));
  setOption("variable-fires", translate(LOCALES.fires));

  setContent("source-title", translate(LOCALES.source));

  setOption("other-sources", translate(LOCALES.others));

  fillLegendMeteorological();

  setInnerHtml("licenseContent", translate(LOCALES.license));
  setInnerHtml("license-btn-more", translate(LOCALES.readMore));
  setInnerHtml("license-btn", translate(LOCALES.accept));

  setContent("help-center", translate(LOCALES.helpCenter));
  setTitle("credits", translate(LOCALES.credits));
  setTitle("login", translate(LOCALES.login));
};

export const fillLegendMeteorological = (): void => {
  setContent("lowerLegend", translate(LOCALES.lower));
  setContent("normalLegend", translate(LOCALES.medium));
  setContent("highLegend", translate(LOCALES.upper));
  setContent("qualityLegend", translate(LOCALES.withoutQuality));
};

export const fillLegendDrought = (): void => {
  setContent("lowerLegend", translate(LOCALES.lowerDrought));
  setContent("normalLegend", translate(LOCALES.mediumDrought));
  setContent("highLegend", translate(LOCALES.upperDrought));
  setContent("qualityLegend", translate(LOCALES.withoutQualityDrought));
};
