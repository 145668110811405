import { Feature, GeoJsonProperties, Geometry } from "geojson";
import { Icon, LatLngExpression, marker } from "leaflet";
import { Blue, Green, Grey, Orange, Red, Yellow } from "./colors";

const HIGHT = 0.75;
const MEDIUM = 0.5;
const RESERVOIR_ICON = "reservoir.png";
const STATION_ICON = "station.png";
const FISH_ICON = "fish.png";

const NO_DATA = -999;

export const getColor = (
  feature: Feature<Geometry, GeoJsonProperties>,
  isBorder = false
): string => {
  const lower = Number(feature.properties.Lower);
  const medium = Number(feature.properties.Medium);
  const upper = Number(feature.properties.Upper);
  if (lower >= medium && lower >= upper) {
    if (lower >= HIGHT || isBorder) return Red.Base;
    if (lower >= MEDIUM) return Red.Light;
    return Red.UltraLight;
  }
  if (medium >= upper && medium >= lower) {
    if (medium >= HIGHT || isBorder) return Yellow.Base;
    if (medium >= MEDIUM) return Yellow.Light;
    return Yellow.UltraLight;
  }
  if (upper >= HIGHT || isBorder) return Blue.Base;
  if (upper >= MEDIUM) return Blue.Light;
  return Blue.UltraLight;
};

export const interpolateColor = (colors: string[], factor: number): string => {
  if (!Number.isFinite(factor)) {
    console.warn(
      "Se requieren un factor para interpolar el color, factor: ",
      factor
    );
    return Grey.Black;
  }
  if (colors.length < 2) {
    console.warn("Se requieren al menos dos colores para la interpolación");
    return Grey.Black;
  }
  if (factor <= 0) return colors[0];
  if (factor >= 1) return colors[colors.length - 1];
  const rgbColors = colors.map((c) => c.split(","));
  const colorArray: string[] = [];
  const index =
    Math.floor(factor / (1 / (colors.length - 1))) + (factor === 1 ? 0 : 1);

  for (let i = 0; i < 3; i++) {
    colorArray.push(
      Math.round(
        Number(rgbColors[index - 1][i]) +
          factor *
            (Number(rgbColors[index][i]) - Number(rgbColors[index - 1][i]))
      ).toString()
    );
  }

  return colorArray.join(",");
};

export const getStyle = (feature: Feature<Geometry, GeoJsonProperties>) => {
  return {
    fillColor: getColor(feature, false),
    weight: 1,
    opacity: 1,
    color: getColor(feature, false),
    fillOpacity: 0.5
  };
};

export const stationStyle = (
  _feature: Feature<Geometry, GeoJsonProperties>,
  coordinates: LatLngExpression
) => {
  const smallIcon = new Icon({
    iconSize: [16, 16],
    iconAnchor: [16, 16],
    popupAnchor: [1, -24],
    iconUrl: STATION_ICON
  });
  return marker(coordinates, { icon: smallIcon });
};

export const fishStyle = (
  _feature: Feature<Geometry, GeoJsonProperties>,
  coordinates: LatLngExpression
) => {
  const smallIcon = new Icon({
    iconSize: [24, 24],
    iconAnchor: [24, 24],
    popupAnchor: [1, -24],
    iconUrl: FISH_ICON
  });
  return marker(coordinates, { icon: smallIcon });
};

export const reservoirStyle = (
  _feature: Feature<Geometry, GeoJsonProperties>,
  coordinates: LatLngExpression
) => {
  const smallIcon = new Icon({
    iconSize: [32, 32],
    iconAnchor: [32, 32],
    popupAnchor: [1, -24],
    iconUrl: RESERVOIR_ICON
  });
  return marker(coordinates, { icon: smallIcon });
};

const gradientStyle = (color: string, noData: boolean) => {
  return {
    fillColor: color,
    weight: noData ? 0 : 1,
    opacity: noData ? 0 : 1,
    color,
    fillOpacity: noData ? 0 : 0.5
  };
};

export const transparentStyle = (
  feature: Feature<Geometry, GeoJsonProperties>
) => {
  return { fillColor: "0,204,255,0", opacity: 0, fillOpacity: 0 };
};

export const whiteBlueGradientStyle = (
  feature: Feature<Geometry, GeoJsonProperties>
) => {
  const noData = feature.properties.value === NO_DATA;
  const color =
    "rgb(" +
    (noData
      ? hexToRgb(Grey.Black)
      : interpolateColor(
          [hexToRgb(Grey.White), hexToRgb(Blue.Intense)],
          feature.properties.rate
        )) +
    ")";

  return gradientStyle(color, noData);
};

export const greenGreenGradientStyle = (
  feature: Feature<Geometry, GeoJsonProperties>
) => {
  const noData = feature.properties.value === NO_DATA;
  const color =
    "rgb(" +
    (noData
      ? hexToRgb(Grey.Black)
      : interpolateColor(
          [hexToRgb(Green.Base), hexToRgb(Green.Intense)],
          feature.properties.rate
        )) +
    ")";

  return gradientStyle(color, noData);
};

export const celesteIntenseStyle = (
  feature: Feature<Geometry, GeoJsonProperties>
) => {
  const noData = feature.properties.value === NO_DATA;
  const color =
    "rgb(" +
    (noData
      ? hexToRgb(Grey.Black)
      : interpolateColor(
          [hexToRgb(Blue.Celeste), hexToRgb(Blue.Intense)],
          feature.properties.rate
        )) +
    ")";

  return gradientStyle(color, noData);
};

export const blueYellowOrangeGradientStyle = (
  feature: Feature<Geometry, GeoJsonProperties>
) => {
  const noData = feature.properties.value === NO_DATA;
  const color =
    "rgb(" +
    (noData
      ? hexToRgb(Grey.Black)
      : interpolateColor(
          [
            hexToRgb(Blue.Medium),
            hexToRgb(Yellow.Medium),
            hexToRgb(Orange.Base)
          ],
          feature.properties.rate
        )) +
    ")";

  return gradientStyle(color, noData);
};

export const redOrangeGreenGradientStyle = (
  feature: Feature<Geometry, GeoJsonProperties>
) => {
  const noData = !hasData(feature);
  const color =
    "rgb(" +
    (noData
      ? hexToRgb(Grey.Black)
      : interpolateColor(
          [
            hexToRgb(Red.Intense),
            hexToRgb(Yellow.Intense),
            hexToRgb(Green.Intense)
          ],
          feature.properties.rate
        )) +
    ")";

  return gradientStyle(color, noData);
};

export const hexToRgb = (hex: string): string => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
        result[3],
        16
      )}`
    : hexToRgb(Grey.Black);
};

const hasData = (feature: Feature<Geometry, GeoJsonProperties>): boolean => {
  return feature.properties.value !== NO_DATA;
};
