export const spanishMessages = {
  accept: "Aceptar",
  anticipationPeriod: "Periodo predicho",
  attributionGVA: `El proyecto INtegrated FORecasting System for Water 
    and the Environment (<b>WATER4CAST</b>) ha sido financiado por el Programa 
    para la promoción de la investigación científica, el desarrollo tecnológico 
    y la innovación en la Comunitat Valenciana para grupos de investigación de 
    excelencia, <b>PROMETEO 2021</b> (ref: PROMETEO/2021/074), de la Consellería de 
    Innovación, Universidades, Ciencia y Sociedad Digital. Generalitat Valenciana.`,
  attributionIGN:
    "PNOA WMS. Cedido por © Instituto Geográfico Nacional de España",
  attributionSCN: "Sistema Cartográfico Nacional",
  basins: "Cuencas hidrográficas",
  credits: "Créditos",
  cropWaterStress: "Estrés hídrico",
  cropWaterStressPlot: "Estrés hídrico",
  cumPlotTitleSeasonal: "Predicción estacional acumulada",
  cumPlotTitleShortTerm: "Predicción a corto plazo acumulada",
  cumPlotTitleSubSeasonal: "Predicción subestacional acumulada",
  day: "día",
  days: "días",
  drought: "Predicción de indicadores de sequía",
  droughtIndexesSpi: "Indicadores de sequía SPI",
  droughtIndexesSpei: "Indicadores de sequía SPEI",
  evaporation: "Tasa de evaporación en embalses",
  fireIndexes: "Indicadores de riesgo de incendios",
  fires: "FWI",
  flow: "Caudal",
  forecast: "Predicción",
  forecastAgricultural: "Predicción agrícola por UDA",
  forecastDate: "Fecha de predicción",
  forecastEco: "Predicción ecohidrológica",
  forecastHabitat: "Predicción del hábitat de los peces",
  forecastManagement: "Predicción de gestión de embalses",
  forecastMeteorological: "Predicción meteorológica",
  forecastParcel: "Predicción agrícola por parcela",
  forecastShortTerm: "Predicción para el día {0}",
  forecastSeasonal: "Predicción para el mes de {0}",
  forecastSubSeasonal: "Predicción para la semana del {0}",
  grossIrrigationNeeds: "Necesidades brutas de riego {0}",
  helpCenter: "Centro de ayuda",
  historicalData: "Datos para el día {0}",
  humidity: "Humedad del suelo",
  inflow: "Entradas a embalse",
  inflows: "Entradas",
  invalidUsername: "Usuario o contraseña incorrectos",
  latitude: "Latitud",
  license:
    "WATER4CAST contiene información modificada por el IIAMA-UPV del Servicio de Cambio Climático de Copernicus. Ni la Comisión Europea ni el ECMWF son responsables del uso que se hace de la información o los datos de Copernicus que contiene.",
  login: "Iniciar sesión...",
  logout: "Cerrar sesión",
  longitude: "Longitud",
  lower: "Menor de lo normal",
  lowerDrought: "Periodo seco",
  mean: "Valor medio",
  medium: "Alrededor de lo normal",
  mediumDrought: "Periodo normal",
  month: "mes",
  monthApril: "Abril",
  monthAugust: "Agosto",
  monthDecember: "Diciembre",
  monthFebruary: "Febrero",
  monthJanuary: "Enero",
  monthJuly: "Julio",
  monthJune: "Junio",
  monthMarch: "Marzo",
  monthMay: "Mayo",
  monthNovember: "Noviembre",
  monthOctober: "Octubre",
  months: "meses",
  monthSeptember: "Septiembre",
  name: "Nombre",
  netIrrigationNeeds: "Necesidades netas de riego {0}",
  noDataAvailable: "No hay datos disponibles para la selección actual",
  others: "Otros:",
  outflows: "Sueltas",
  password: "Contraseña",
  plotTitleSeasonalCoordinate:
    "Predicción estacional con el modelo {0}<br> coordenadas ({1}° , {2}°) - {3}",
  plotTitleSeasonal: "Predicción estacional",
  plotTitleShortTerm: "Predicción a corto plazo",
  plotTitleSubSeasonal: "Predicción subestacional",
  potentialHabitat: "Hábitat potencial últil de especies piscícolas nativas",
  precipitation: "Precipitación",
  readMore: "Leer más...",
  seasonal: "Estacional",
  sessionStart: "Inicio de sesión",
  shortTerm: "Corto plazo",
  solarRadiation: "Radiación solar",
  source: "Origen",
  startSession: "Iniciar sesión",
  storedVolume: "Volumen embalsado",
  subSeasonal: "Subestacional",
  successRate: "Acierto histórico",
  spi6: "SPI 6 meses",
  spi12: "SPI 12 meses",
  spi18: "SPI 18 meses",
  spi24: "SPI 24 meses",
  spei6: "SPEI 6 meses",
  spei12: "SPEI 12 meses",
  spei18: "SPEI 18 meses",
  spei24: "SPEI 24 meses",
  temperature: "Temperatura",
  temperatureMax: "Temperatura máxima",
  temperatureMin: "Temperatura mínima",
  terrain: "Terreno",
  upper: "Mayor de lo normal",
  upperDrought: "Periodo húmedo",
  username: "Nombre de usuario",
  value: "Valor",
  variable: "Variable",
  warnNotPresent: "Id '{0}' not present in the document",
  warnNotPresentWithPosition:
    "Position '{0}' not present in Select element with id '{1}'",
  week: "semana",
  weeks: "semanas",
  welcome: "Bienvenido, <b>{0}</b>",
  wind: "Viento",
  withoutQuality: "Sin calidad",
  withoutQualityDrought: "Sin indicador",
  year: "Año"
};

export type LocaleMessage = { [key in keyof typeof spanishMessages]: string };
