import { valenciaMessages } from "./locales/ca-Ca";
import { englishMessages } from "./locales/en-En";
import { LocaleMessage, spanishMessages } from "./locales/es-Es";

export enum Language {
  Español,
  Valencia,
  English
}

export const months = {
  1: "monthJanuary",
  2: "monthFebruary",
  3: "monthMarch",
  4: "monthApril",
  5: "monthMay",
  6: "monthJune",
  7: "monthJuly",
  8: "monthAugust",
  9: "monthSeptember",
  10: "monthOctober",
  11: "monthNovember",
  12: "monthDecember"
};

export const variables = {
  prec: "precipitation",
  temp: "temperature",
  tasmin: "temperatureMin",
  tasmax: "temperatureMax",
  ssrd: "solarRadiation",
  wind: "wind",
  flow: "flow",
  inflow: "inflow",
  humid: "humidity",
  rer: "evaporation",
  ninpm3: "netIrrigationNeeds",
  ninpmm: "netIrrigationNeeds",
  ginudam3: "grossIrrigationNeeds",
  ninudamm: "netIrrigationNeeds",
  cwsp: "cropWaterStressPlot",
  cwsuda: "cropWaterStress",
  habPotUtil: "habitatPotencial",
  volEmb: "storedVolume",
  inflows: "inflows",
  outflows: "outflows",
  drought: "drought",
  fires: "fires",
  SPI6: "spi6",
  SPI12: "spi12",
  SPI18: "spi18",
  SPI24: "spi24",
  SPEI6: "spei6",
  SPEI12: "spei12",
  SPEI18: "spei18",
  SPEI24: "spei24"
};

export const units = {
  prec: "mm",
  temp: "°C",
  tasmin: "°C",
  tasmax: "°C",
  ssrd: "W/m²",
  wind: "m/s",
  flow: "m³/s",
  inflow: "m³/s",
  humid: "",
  rer: "mm",
  ginudam3: "m³",
  ninudamm: "mm",
  cwsuda: "",
  ninpm3: "m³",
  ninpmm: "mm",
  cwsp: "",
  SPI6: "",
  SPI12: "",
  SPI18: "",
  SPI24: "",
  SPEI6: "",
  SPEI12: "",
  SPEI18: "",
  SPEI24: "",
  fires: ""
};

const localeMessages = (): LocaleMessage => {
  switch (getLanguage()) {
    case Language.Español:
      return spanishMessages;
    case Language.English:
      return englishMessages;
    case Language.Valencia:
      return valenciaMessages;
  }
};

export const getLanguage = (): Language => {
  if (
    window.navigator.language === "es" ||
    window.navigator.language.indexOf(`es-`) >= 0
  )
    return Language.Español;

  if (
    window.navigator.language === "en" ||
    window.navigator.language.indexOf(`en-`) >= 0
  )
    return Language.English;

  if (
    window.navigator.language === "ca" ||
    window.navigator.language.indexOf(`ca-`) >= 0
  )
    return Language.Valencia;

  return Language.Español;
};

export const translate = (key?: LocaleMessage, ...args: string[]): string => {
  if (!key) {
    console.warn("Translation key not defined");
    return "";
  }
  const localKey = String(key) as keyof LocaleMessage;
  let message = localeMessages()[localKey];

  if (!args) return message;

  args.forEach((word, index) => {
    const pattern = `\\{${index}\\}`;
    message = message.replace(new RegExp(pattern, "g"), word);
  });
  return message;
};

export const LocaleKeys = (): { [key: string]: LocaleMessage } => {
  const result = Object.assign(
    {},
    ...Object.keys(localeMessages()).map((x) => ({ [x]: x }))
  );
  return result;
};
