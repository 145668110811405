export const valenciaMessages = {
  accept: "Accepta",
  anticipationPeriod: "Període Previst",
  attributionGVA: `El projecte INtegrated FORecasting System for Water and the Environment 
      (WATER4CAST) ha estat finançat pel Programa de promoció de la investigació científica, 
      desenvolupament tecnològic i innovació en la Comunitat Valenciana per a grups d'excel·lència 
      en recerca, PROMETEO 2021 (ref: PROMETEO/2021/074), de la Conselleria d'Innovació, 
      Universitats, Ciència i Societat Digital de la Generalitat Valenciana.`,
  attributionIGN: "PNOA WMS. Cedit per © Institut Geogràfic Nacional d'Espanya",
  attributionSCN: "Sistema Cartogràfic Nacional",
  basins: "Conques Hidrogràfiques",
  credits: "Crèdits",
  cropWaterStress: "Estrès Hídric",
  cropWaterStressPlot: "Estrès Hídric",
  cumPlotTitleSeasonal: "Predicció Acumulativa Estacional",
  cumPlotTitleShortTerm: "Predicció Acumulativa a Curt Termini",
  cumPlotTitleSubSeasonal: "Predicció Acumulativa Subestacional",
  day: "dia",
  days: "dies",
  droughtIndexesSpi: "Indicador de Sequera SPI",
  droughtIndexesSpei: "Indicador de Sequera SPEI",
  drought: "Predicció de l'Indicador de Sequera",
  evaporation: "Taxa d'Evaporació en Embassaments",
  fireIndexes: "Predicció de l'Indicador d'Incendis",
  fires: "FWI",
  flow: "Caudal",
  forecast: "Predicció",
  forecastAgricultural: "Predicció Agrícola per UDA",
  forecastDate: "Data de la Predicció",
  forecastEco: "Predicció Ecohidrològica",
  forecastHabitat: "Predicció de l'Hàbitat dels Peixos",
  forecastManagement: "Predicció de la Gestió d'Embassaments",
  forecastMeteorological: "Predicció Meteorològica",
  forecastParcel: "Predicció Agrícola per Parcel·la",
  forecastShortTerm: "Predicció per al dia {0}",
  forecastSeasonal: "Predicció per al Mes de {0}",
  forecastSubSeasonal: "Predicció per a la Setmana del {0}",
  grossIrrigationNeeds: "Necessitats Brutes d'Inundació {0}",
  historicalData: "Dades per al dia {0}",
  helpCenter: "Centre de ajuda",
  humidity: "Humitat del Sòl",
  indexes: "Indicadors",
  inflow: "Entrades a l'Embassament",
  inflows: "Entrades",
  invalidUsername: "Nom d'usuari o contrasenya incorrecta",
  latitude: "Latitud",
  license: `WATER4CAST conté informació modificada per IIAMA-UPV del Servei de Canvi 
      Climàtic de Copèrnic. Ni la Comissió Europea ni l'ECMWF són responsables de l'ús de 
      la informació o les dades de Copèrnic contingudes en aquesta.`,
  login: "Inicia la sessió...",
  logout: "Tanca la sessió",
  longitude: "Longitud",
  lower: "Més baix de l'habitual",
  lowerDrought: "Periode sec",
  mean: "Valor Mitjà",
  medium: "Al voltant de l'habitual",
  mediumDrought: "Periode normal",
  month: "mes",
  monthApril: "Abril",
  monthAugust: "Agost",
  monthDecember: "Desembre",
  monthFebruary: "Febrer",
  monthJanuary: "Gener",
  monthJuly: "Juliol",
  monthJune: "Juny",
  monthMarch: "Març",
  monthMay: "Maig",
  monthNovember: "Novembre",
  monthOctober: "Octubre",
  months: "mesos",
  monthSeptember: "Setembre",
  name: "Nom",
  netIrrigationNeeds: "Necessitats Netes d'Inundació {0}",
  noDataAvailable: "No hi ha dades disponibles per a la selecció actual",
  others: "Altres:",
  outflows: "Sortides",
  password: "Contrasenya",
  plotTitleSeasonalCoordinate:
    "Predicció Estacional amb el model {0}<br> coordenades ({1}° , {2}°) - {3}",
  plotTitleSeasonal: "Predicció Estacional",
  plotTitleShortTerm: "Predicció a Curt Termini",
  plotTitleSubSeasonal: "Predicció Subestacional",
  potentialHabitat: "Hàbitat Potencial d'Especies de Peixos Nadius",
  precipitation: "Precipitació",
  readMore: "Llegeix més...",
  seasonal: "Estacional",
  sessionStart: "Inici de la Sessió",
  shortTerm: "Curt Termini",
  solarRadiation: "Radiació Solar",
  source: "Origen",
  startSession: "Inicia la Sessió",
  storedVolume: "Volum de l'Embassament",
  subSeasonal: "Subestacional",
  successRate: "Precisió Històrica",
  spi6: "SPI 6 mesos",
  spi12: "SPI 12 mesos",
  spi18: "SPI 18 mesos",
  spi24: "SPI 24 mesos",
  spei6: "SPEI 6 mesos",
  spei12: "SPEI 12 mesos",
  spei18: "SPEI 18 mesos",
  spei24: "SPEI 24 mesos",
  temperature: "Temperatura",
  temperatureMax: "Temperatura Màxima",
  temperatureMin: "Temperatura Mínima",
  terrain: "Terreny",
  upper: "Més alt de l'habitual",
  upperDrought: "Periode humit",
  username: "Nom d'Usuari",
  value: "Valor",
  variable: "Variable",
  warnNotPresent: "Id '{0}' no està present en el document",
  warnNotPresentWithPosition:
    "Posició '{0}' no està present en l'element Select amb id '{1}'",
  week: "setmana",
  weeks: "setmanes",
  welcome: "Benvingut, <b>{0}</b>",
  wind: "Vent",
  withoutQuality: "Sense Qualitat",
  withoutQualityDrought: "Sense Indicador",
  year: "Any"
};

export type LocaleMessage = { [key in keyof typeof valenciaMessages]: string };
