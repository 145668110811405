export enum ModelVariables {
  Precipitation = "prec",
  Temperature = "temp",
  MaxTemperature = "tasmax",
  MinTemperature = "tasmin",
  SolarRadiation = "ssrd",
  Wind = "wind",
  Flow = "flow",
  Inflow = "inflow",
  Humidity = "humid",
  Evaporation = "rer",
  GrossIrrigationNeeds = "ginudam3",
  NetIrrigationNeeds = "ninudamm",
  CropWaterStress = "cwsuda",
  CropWaterStressPlot = "cwsp",
  Fires = "fires",
  Drought = "drought",
  PotentialHabitat = "habPotUtil",
  NetIrrigationNeedsPlotCubicMeters = "ninpm3",
  NetIrrigationNeedsMillimeters = "ninpmm",
  SPI6 = "SPI6",
  SPI12 = "SPI12",
  SPI18 = "SPI18",
  SPI24 = "SPI24",
  SPEI6 = "SPEI6",
  SPEI12 = "SPEI12",
  SPEI18 = "SPEI18",
  SPEI24 = "SPEI24"
}

const SUB_SEASONAL_SOURCE = "S2S_NCEP_CFSv2";
const SHORT_TERM_SOURCE_GFS = "GFS";
const SHORT_TERM_SOURCE_LANDSAT = "LANDSAT";

const SUB_SEASONAL_VARIABLES: ModelVariables[] = [
  ModelVariables.Precipitation,
  ModelVariables.SolarRadiation,
  ModelVariables.MaxTemperature,
  ModelVariables.MinTemperature,
  ModelVariables.Temperature,
  ModelVariables.Wind,
  ModelVariables.Evaporation,
  ModelVariables.GrossIrrigationNeeds,
  ModelVariables.NetIrrigationNeeds,
  ModelVariables.NetIrrigationNeedsPlotCubicMeters,
  ModelVariables.NetIrrigationNeedsMillimeters,
  ModelVariables.Fires,
  ModelVariables.Flow,
  ModelVariables.Inflow
];

const SHORT_TERM_SOURCE_LANDSAT_VARIABLES: ModelVariables[] = [
  ModelVariables.CropWaterStress,
  ModelVariables.CropWaterStressPlot,
  ModelVariables.PotentialHabitat
];

const DROUGHT_VARIABLES: ModelVariables[] = [
  ModelVariables.SPI6,
  ModelVariables.SPI12,
  ModelVariables.SPI18,
  ModelVariables.SPI24,
  ModelVariables.SPEI6,
  ModelVariables.SPEI12,
  ModelVariables.SPEI18,
  ModelVariables.SPEI24
];

const INDEX_VARIABLES: ModelVariables[] = [
  ModelVariables.CropWaterStress,
  ModelVariables.CropWaterStressPlot
];

const SHORT_TERM_SOURCE_GFS_VARIABLES: ModelVariables[] = [
  ModelVariables.Precipitation,
  ModelVariables.SolarRadiation,
  ModelVariables.MaxTemperature,
  ModelVariables.MinTemperature,
  ModelVariables.Temperature,
  ModelVariables.Wind,
  ModelVariables.Flow,
  ModelVariables.Inflow,
  ModelVariables.Evaporation,
  ModelVariables.GrossIrrigationNeeds,
  ModelVariables.NetIrrigationNeeds,
  ModelVariables.NetIrrigationNeedsPlotCubicMeters,
  ModelVariables.NetIrrigationNeedsMillimeters
];

export const getSubSeasonalSource = (variable: ModelVariables): string => {
  return SUB_SEASONAL_VARIABLES.includes(variable) ? SUB_SEASONAL_SOURCE : "";
};

export const getShortTermSource = (variable: ModelVariables): string => {
  if (SHORT_TERM_SOURCE_GFS_VARIABLES.includes(variable))
    return SHORT_TERM_SOURCE_GFS;
  return SHORT_TERM_SOURCE_LANDSAT_VARIABLES.includes(variable)
    ? SHORT_TERM_SOURCE_LANDSAT
    : "";
};

export const isForecastVariable = (variable: ModelVariables): boolean => {
  return !SHORT_TERM_SOURCE_LANDSAT_VARIABLES.includes(variable);
};

export const isIndexVariable = (variable: ModelVariables): boolean => {
  return INDEX_VARIABLES.includes(variable);
};

export const isDroughtVariable = (variable: ModelVariables): boolean => {
  return DROUGHT_VARIABLES.includes(variable);
};

export const isStatsVariable = (variable: ModelVariables): boolean => {
  return [
    ModelVariables.Evaporation,
    ModelVariables.GrossIrrigationNeeds,
    ModelVariables.NetIrrigationNeeds
  ].includes(variable);
};

export const isParcelVariable = (variable: ModelVariables): boolean => {
  return [
    ModelVariables.NetIrrigationNeedsPlotCubicMeters,
    ModelVariables.NetIrrigationNeedsMillimeters
  ].includes(variable);
};

export const isSPI = (variable: ModelVariables): boolean => {
  return [
    ModelVariables.SPI6,
    ModelVariables.SPI12,
    ModelVariables.SPI18,
    ModelVariables.SPI24,
    ModelVariables.SPEI6,
    ModelVariables.SPEI12,
    ModelVariables.SPEI18,
    ModelVariables.SPEI24
  ].includes(variable);
};
