const DIFFERENTIAL = 0.0000000001;

export const round = (value: number, decimals = 0): number => {
  const factorOfTen = Math.pow(10, decimals);
  return Number(Math.round(value * factorOfTen + DIFFERENTIAL) / factorOfTen);
};

export const max = (values: number[]): number | undefined => {
  if (!values || values.length === 0) {
    console.warn(`Calling max function without data`);
    return undefined;
  }

  let result = values[0];
  values.forEach((value) => {
    if (value > result) {
      result = value;
    }
  });
  return result;
};

export const min = (values: number[]): number | undefined => {
  if (!values || values.length === 0) {
    console.warn(`Calling max function without data`);
    return undefined;
  }

  let result = values[0];
  values.forEach((value) => {
    if (value < result) {
      result = value;
    }
  });
  return result;
};
