import { hideLoader, showLoader } from "../html/loader";

export const getGeoJSON = async (
  url: string
): Promise<GeoJSON.FeatureCollection> => {
  showLoader();
  const text = await fetch(url).then((res) => res.text());
  hideLoader();
  return JSON.parse(text);
};
