import { PollingMode, getClient } from "configcat-js";

type Flags = Record<string, boolean>;

export class FeatureFlags {
  private static flags: Flags = {};

  static fromEmail = async (email: string) => {
    const client = getClient(process.env.featuresClient, PollingMode.AutoPoll);

    await client.forceRefreshAsync();
    const params = { identifier: email };
    const settings = await client.getAllValuesAsync(params);

    settings.forEach(({ settingKey, settingValue }) => {
      this.flags[settingKey] = settingValue as boolean;
    });
  };

  static isEnabled = (feature: string): boolean => {
    return this.flags[feature];
  };

  static reset = () => {
    this.flags = {};
  };
}
